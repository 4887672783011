import { ResultOf } from '@graphql-typed-document-node/core';
import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../config';
import { GameMode } from '../consts';
import {
  AnticipationInfo,
  AnticipationReelType,
  AnticipationType,
  ISettledBet,
  MessageFreeSpinEndBannerProps,
  MessageLevelUpBannerProps,
  ReelSet,
  ReelStopSoundType,
} from '../global.d';
import {
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setDefaultReelSetId,
  setForceReelStop,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsAllowForceReelStop,
  setIsAutoSpins,
  setIsBuyFeaturePurchased,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsReSpinsWin,
  setIsRevokeThrowingError,
  setIsSnowCorrect,
  setIsSpinShortCut,
  setIsStopOnFeatureWin,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastBaseReelPositions,
  setLastRegularReelPositions,
  setLastRegularWinAmount,
  setNextResult,
  setPreSnowCount,
  setPrevReelSet,
  setPrevReelsPosition,
  setReelSetId,
  setResumeDuringFreeSpin,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { userBonusFragment } from '../gql/fragment';
import { isStoppedGql } from '../gql/query';
import {
  bannerWaitBgmPlay,
  checkNonBonusAutoSpinStop,
  getAnimationDurationFromSpine,
  getBgTypeForGameMode,
  getBonusFromRewards,
  getBonusKind,
  getGameModeByBonusId,
  getNonNullableValue,
  getSpinResult3x5,
  hasWin,
  isBaseGameMode,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinsBaseMode,
  isFreeSpinsGameMode,
  isFreeSpinsMode,
  isRespinMode,
  normalizeCoins,
  showCurrency,
  transposeFlatIconToSlotIdArray,
  updateCoinValueAfterBonuses,
} from '../utils';

import { SlotId } from './../config/config';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import { AnnounceContainer } from './announce/announceContainer';
import { AnnounceConvTbl, AnnounceDataTbl, AnnounceType } from './announce/config';
import { getRandomFromUUID, getResultFromTbl } from './announce/utils';
import Backdrop from './backdrop/backdrop';
import Background, { BgType } from './background/background';
import bgmControl, { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { BuyFeaturePopup } from './buyFeature/buyFeaturePopup';
import { BuyFeaturePopupConfirm } from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  EventTypes,
  FREESPIN_ENDING_DELAY_DURATION,
  FREE_SPINS_ROUND_INTERVAL,
  FREE_SPINS_ROUND_INTERVAL_TURBO,
  FREE_SPINS_TIME_OUT_BANNER,
  FREE_SPIN_START_REEL_POSITIONS,
  JINGLE_TO_WIN_DURATION,
  REELS_AMOUNT,
  RESPIN_REEL_INDEX,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_REELMASK_HEIGHT,
  SLOT_REELMASK_WIDTH,
  SLOT_REELMASK_X,
  SLOT_REELMASK_Y,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
  WinStages,
  eventManager,
} from './config';
import { BonusKind } from './config/bonusInfo';
import { createUIButtonContainer } from './controlButtons';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/lineContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import {
  SNOW_LEVEL_MAX,
  SNOW_LEVEL_TO_BG,
  SNOW_LEVEL_TO_LEVEL_UP_AUDIO,
  SNOW_LEVEL_TO_WAIT_DURATION_AUDIO,
} from './snow/config';
import SnowMeter from './snow/snowMeter';
import SpinAnimation from './spin/spin';
import ReelTintContainer from './tint/reelTintContainer';
import { SlotsAnimationContainer } from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: RecursiveNonNullable<ISlotConfig>;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public spinResult: Icon[];

  public stopCallback: (() => void) | null = null;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      application,
      slotConfig as RecursiveNonNullable<ISlotConfig>,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public reelTintContainer: ReelTintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotAnimationContainer: SlotsAnimationContainer;
  public snowMeter: SnowMeter;

  public announceContainer: AnnounceContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public buyFeatureBtn?: BuyFeatureBtn;

  public buyFeaturePopup?: BuyFeaturePopup;

  public buyFeaturePopupConfirm?: BuyFeaturePopupConfirm;

  private phoenix: Phoenix;

  public linesContainer: LinesContainer;

  private announceType: AnnounceType = 'None';

  private anticipationInfo: AnticipationInfo = { type: 'None', reels: [], totalSnowCount: 0 };

  private slotMachineContainer: PIXI.Container;

  private backDrop?: Backdrop;

  private constructor(
    application: PIXI.Application,
    slotConfig: RecursiveNonNullable<ISlotConfig>,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const lastResult = setUserLastBetResult();
    const startPosition = lastResult
      ? getNonNullableValue(lastResult.result.reelPositions)
      : slotConfig.settings.startPosition;
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    const reelSetId = lastResult ? lastResult.reelSetId : setDefaultReelSetId();
    const reelSet = { ...this.slotConfig.reels.find((reelSet) => reelSet.id === reelSetId)! };

    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.reelTintContainer = new ReelTintContainer();
    const spinResult = getSpinResult3x5({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    setPrevReelSet(reelSet);
    this.spinResult = spinResult;
    this.slotAnimationContainer = new SlotsAnimationContainer();
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.announceContainer = new AnnounceContainer(spinResult);
    this.background = new Background();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);
    this.linesContainer = new LinesContainer(slotConfig.lines);
    this.snowMeter = new SnowMeter();

    this.gameView = new GameView({
      announceContainer: this.announceContainer,
      slotStopDisplayContainer: this.slotAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      miniPayTableContainer: this.miniPayTableContainer,
      reelTintContainer: this.reelTintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      linesContainer: this.linesContainer,
      snowMeter: this.snowMeter,
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.buyFeatureBtn = new BuyFeatureBtn();
      this.buyFeaturePopup = new BuyFeaturePopup();
      this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
      this.backDrop = new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      this.gameView.addChild(this.buyFeatureBtn, this.backDrop, this.buyFeaturePopup, this.buyFeaturePopupConfirm);
    }
    const uiButtonContainer = createUIButtonContainer();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.safeArea.addChild(this.gameView);
    this.phoenix = new Phoenix();

    this.slotMachineContainer = new PIXI.Container();
    this.slotMachineContainer.addChild(this.background);
    this.slotMachineContainer.addChild(this.safeArea);
    this.slotMachineContainer.addChild(this.bottom);
    this.slotMachineContainer.addChild(uiButtonContainer);
    this.slotMachineContainer.addChild(this.phoenix);
    this.fadeArea = new FadeArea();
    this.slotMachineContainer.addChild(this.fadeArea);

    this.application.stage.addChild(this.slotMachineContainer);

    this.gameView.interactive = true;
    this.gameView.hitArea = new PIXI.Rectangle(
      SLOT_REELMASK_X,
      SLOT_REELMASK_Y,
      SLOT_REELMASK_WIDTH,
      SLOT_REELMASK_HEIGHT,
    );

    this.gameView.on('mousedown', () => {
      this.skipWinAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipWinAnimations();
    });

    if (setBrokenBuyFeatureGame() !== '') {
      const initWait = Tween.createDelayAnimation(1000);
      initWait.addOnComplete(() => {
        eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND, setBrokenBuyFeatureGame());
      });
      initWait.start();
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    } else if (setBrokenGame()) {
      this.state = SlotMachineState.WINNING;
      this.onBrokenGame();
      setResumeDuringFreeSpin(true);
    }
  }

  private onBrokenGame(): void {
    const lastResult = setUserLastBetResult()!;

    const snowMeter = lastResult.data.features.gameRoundStore.snowMeter ?? {
      count: 0,
      countIncrease: 0,
      currentRound: 0,
      level: 1,
      levelUp: false,
      totalRoundsCount: 12,
    };
    const gamemode = getGameModeByBonusId(setCurrentBonus().bonusId);
    const reelPositions = lastResult.result.reelPositions;

    const roundAddedTotal = snowMeter.levelUp ? 6 + snowMeter.totalRoundsCount : snowMeter.totalRoundsCount;
    const isLevelUp = snowMeter.levelUp && snowMeter.level < SNOW_LEVEL_MAX ? true : false;
    const snowLevel = isLevelUp ? snowMeter.level : snowMeter.level > 1 ? snowMeter.level - 1 : 0;
    const snowCount = isLevelUp ? 0 : snowMeter.count;
    const bgType: BgType = getBgTypeForGameMode(gamemode, isLevelUp ? snowLevel + 1 : snowLevel);

    const settings = {
      mode: gamemode,
      reelPositions: getNonNullableValue(reelPositions),
      reelSetId: lastResult.reelSetId,
      bgType: bgType,
    };
    eventManager.emit(EventTypes.CHANGE_MODE, settings);

    setPreSnowCount(snowCount);
    switch (gamemode) {
      case GameMode.REGULAR:
        if (
          lastResult.userBonus?.bonusId === BonusKind.FREE_SPINS ||
          lastResult.userBonus?.bonusId === BonusKind.RESPIN_FREE_SPIN
        ) {
          this.updateRegularWin(lastResult.result.winCoinAmount);
        }
        break;
      case GameMode.REGULAR_RESPIN:
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
        setIsReSpinsWin(true);
        break;
      case GameMode.FREE_SPINS:
        setIsFreeSpinsWin(true);

        setCurrentBonus({
          ...setCurrentBonus(),
          rounds: roundAddedTotal,
          roundsPlayed: snowMeter.currentRound,
        });
        bgmControl.setRestrict(true);
        this.updateSnowMeter(snowCount, snowLevel, true);
        bgmControl.setRestrict(false);

        eventManager.emit(EventTypes.SNOW_COUNT_FORCE_UPDATE);

        break;
      case GameMode.FREE_SPINS_RESPIN:
        setIsFreeSpinsWin(true);
        setIsReSpinsWin(true);

        setCurrentBonus({
          ...setCurrentBonus(),
          rounds: roundAddedTotal,
          roundsPlayed: snowMeter.currentRound,
        });
        this.updateSnowMeter(snowCount, snowLevel, true);
        eventManager.emit(EventTypes.SNOW_COUNT_FORCE_UPDATE);

        break;
      case GameMode.BUY_FEATURE:
        break;
      default:
        break;
    }

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setCurrentFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    }
    eventManager.emit(EventTypes.SET_RESPIN_VIEW);
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
  }

  private updateSnowMeter(snowCount: number, snowLevel: number, isUpdateLevel: boolean) {
    if (isUpdateLevel) {
      eventManager.emit(EventTypes.SET_FREESPINS_SNOW_LEVEL, snowLevel);
      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: setGameMode(),
        bgType: SNOW_LEVEL_TO_BG[snowLevel] ?? 'freeSpin01',
      });
    }
    eventManager.emit(EventTypes.SET_FREESPINS_SNOW_COUNT, snowCount);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOP_ANIMATION_COMPLETE, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.END_FREESPINS, this.endFreeSpins.bind(this));

    eventManager.addListener(EventTypes.SET_IS_ALLOW_FORCE_REEL_STOP, this.checkForceReelStop.bind(this));
  }

  public throwTimeoutError(): void {
    if (setIsAutoSpins()) setIsAutoSpins(false);
    setIsAllowForceReelStop(false);
    setIsSpinShortCut(false);
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    AudioApi.stop({ type: ISongs.XT004S_spin_loop });
    this.setState(SlotMachineState.IDLE);
    setIsSpinShortCut(false);
    setForceReelStop(false);
    setIsAllowForceReelStop(false);

    this.isSpinInProgressCallback();

    const spinResult = getSpinResult3x5({
      reelPositions: setPrevReelsPosition(),
      reelSet: setPrevReelSet(),
      icons: this.slotConfig.icons,
    });
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
  }

  private changeReelSetOnModechange(reelSetId: string, reelPositions: number[]) {
    //change reelset & display reel
    setReelSetId(reelSetId);
    const reels = getNonNullableValue(setSlotConfig().reels);
    const reelSet = reels.find((reels) => reels.id === reelSetId);
    const spinResult = getSpinResult3x5({
      reelPositions: reelPositions.slice(0, REELS_AMOUNT),
      reelSet: reelSet!,
      icons: getNonNullableValue(setSlotConfig().icons),
    });
    eventManager.emit(EventTypes.CHANGE_REEL_SET, {
      reelSet: reelSet!,
      reelPositions: reelPositions,
    });
    this.miniPayTableContainer.setSpinResult(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    setPrevReelSet(reelSet);
    setPrevReelsPosition(reelPositions);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
    }
    if (!(previousGameMode === GameMode.REGULAR_RESPIN && currentGameMode === GameMode.REGULAR)) {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION, settings.mode === GameMode.REGULAR);
    }

    switch (settings.mode) {
      case GameMode.REGULAR:
        setIsFreeSpinsWin(false);
        setIsReSpinsWin(false);
        setCurrentBonus({
          ...setCurrentBonus(),
          isActive: false,
        });
        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, setNextResult()!.balance.settled);
        if (previousGameMode === GameMode.FREE_SPINS || previousGameMode === GameMode.FREE_SPINS_RESPIN) {
          //change reelset & display reel
          this.changeReelSetOnModechange(settings.reelSetId, settings.reelPositions);
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({
              currency: setCurrency(),
              value: normalizeCoins(setCurrentFreeSpinsTotalWin()),
              showCurrency: showCurrency(setCurrency()),
            }),
          );
        }
        updateCoinValueAfterBonuses();
        this.setState(SlotMachineState.IDLE);
        break;
      case GameMode.REGULAR_RESPIN:
      case GameMode.FREE_SPINS_RESPIN:
        const respinStartDelay = Tween.createDelayAnimation(1000);
        respinStartDelay.addOnComplete(() => {
          this.setState(SlotMachineState.IDLE);
        });
        respinStartDelay.start();
        //FreezeAnimation
        eventManager.emit(EventTypes.START_RESPIN_FREEZE_ANIMATION);
        break;
      case GameMode.FREE_SPINS:
        eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);

        const bonus = setCurrentBonus();

        // todo replace with normal error
        if (!bonus) throw new Error('Something went wrong');
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
        const callback = () => {
          this.setState(SlotMachineState.IDLE);
        };
        if (!setBrokenGame()) {
          setCurrentBonus({
            ...bonus,
          });
        }

        const isAutoSkipTitle = setIsContinueAutoSpinsAfterFeature() && !setIsStopOnFeatureWin() ? true : false;

        if (previousGameMode === GameMode.REGULAR) {
          this.changeReelSetOnModechange(settings.reelSetId, settings.reelPositions);

          if (setBrokenGame() && setUserLastBetResult()?.data.features.gameRoundStore.snowMeter) {
            const brokenGameDelay = Tween.createDelayAnimation(2000);
            brokenGameDelay.addOnComplete(() => {
              this.setState(SlotMachineState.IDLE);
            });
            brokenGameDelay.start();
          } else if (isAutoSkipTitle) {
            setLastRegularReelPositions(getNonNullableValue(setNextResult()?.bet.result.reelPositions)); //memory regular reelposition

            // auto continue
            const titleDispWait = Tween.createDelayAnimation(setBrokenGame() ? 2000 : 2000);
            titleDispWait.addOnComplete(() => {
              eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
            });

            eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
              callback: callback,
              preventDefaultDestroy: false,
              onInitCallback: () => {
                titleDispWait.start();
              },
            });
            BgmControl.playBgm('freeSpin01', false, true);
          } else {
            this.isSlotBusyCallback();
            //title wait
            eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
              callback: callback,
              preventDefaultDestroy: false,
            });
            BgmControl.playBgm('freeSpin01', false, true);
          }
        } else {
          this.isSlotBusyCallback();
          this.setState(SlotMachineState.IDLE);
        }
        break;
      case GameMode.BUY_FEATURE:
        break;
      default:
        break;
    }
  }

  private startFreeSpins(): void {
    setLastBaseReelPositions(getNonNullableValue(this.nextResult?.bet.result.reelPositions));
    eventManager.emit(EventTypes.SET_FREESPINS_SNOW_LEVEL, 0);
    BgmControl.setRestrict(true);
    BgmControl.fadeOutAll(500);

    const modeChangeCallback = () => {
      setIsFreeSpinsWin(true);
      BgmControl.setRestrict(false);

      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.FREE_SPINS,
        reelPositions: FREE_SPIN_START_REEL_POSITIONS,
        reelSetId: setCurrentBonus().bonus?.reelSetId!,
        bgType: 'freeSpin01',
      });
    };
    eventManager.emit(EventTypes.TRANSITION_START, {
      type: 'Fade',
      fadeOutDuration: 1000,
      fadeInDuration: 1000,
      tint: 0xffffff,
      callback: modeChangeCallback,
    });
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, true);
  }

  private startReSpins(): void {
    setIsReSpinsWin(true);
    const nextMode = isFreeSpinsBaseMode(setGameMode()) ? GameMode.FREE_SPINS_RESPIN : GameMode.REGULAR_RESPIN;

    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: nextMode,
      reelPositions: getNonNullableValue(this.nextResult?.bet.result.reelPositions),
      reelSetId: setCurrentBonus().bonus?.reelSetId!,
    });
  }

  private hideWinCount() {
    this.skipWinAnimations();

    eventManager.emit(EventTypes.SET_WIN_VISIBILITY, WinStages.None);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private async endFreeSpins(): Promise<void> {
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      const modeChangeCallback = () => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
        eventManager.emit(EventTypes.CHANGE_MODE, {
          mode: GameMode.REGULAR,
          reelSetId: setDefaultReelSetId(),
          reelPositions: setLastBaseReelPositions(),
          bgType: 'default',
        });
      };
      eventManager.emit(EventTypes.TRANSITION_START, {
        type: 'Fade',
        fadeOutDuration: 1000,
        fadeInDuration: 1000,
        tint: 0xffffff,
        callback: modeChangeCallback,
      });
    };
    this.hideWinCount();

    const freeSpinEndMessageBannerOptions: MessageFreeSpinEndBannerProps = {
      totalWin: `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
      totalWinAmount: setFreeSpinsTotalWin(),
      totalRoundPlayed: setCurrentBonus().roundsPlayed,
      preventDefaultDestroy: true,
    };

    if (!setIsContinueAutoSpinsAfterFeature()) {
      freeSpinEndMessageBannerOptions.callback = callback;
    } else {
      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });
      freeSpinEndMessageBannerOptions.onInitCallback = () => delay.start();
    }
    eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, freeSpinEndMessageBannerOptions);
    this.skipWinAnimations();
    setBrokenGame(false);
    this.isSlotBusyCallback();
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  private removeErrorHandler(reelIdx = REELS_AMOUNT - 1): void {
    this.reelsContainer.reels[reelIdx]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      setIsSpinShortCut(true);
      if (this.nextResult) {
        if (!this.isReadyForStop && setIsAllowForceReelStop()) {
          this.isReadyForStop = true;
          this.removeErrorHandler(REELS_AMOUNT - 1);
          this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            getNonNullableValue(this.nextResult.bet.result.reelPositions),
            this.anticipationInfo,
            this.announceType,
            this.spinResult,
          );
        }
        if (setIsAllowForceReelStop()) {
          this.stopSpin();
        }
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.skipWinAnimations();
      eventManager.emit(EventTypes.START_SPIN_ANIMATION, isTurboSpin); // change order skipAnimation
      //eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.announceType = 'None';
      this.anticipationInfo = { type: 'None', reels: [], totalSnowCount: 0 };
      setIsSpinShortCut(false); //dont change order
      setIsAllowForceReelStop(true); //dont change order
      setForceReelStop(false);

      this.setState(SlotMachineState.SPIN);

      let spinAnimation: AnimationGroup;
      if (isBuyFeatureMode()) {
        spinAnimation = this.getSynchroSpinAnimation(!!isTurboSpin);
      } else {
        spinAnimation = this.getSpinAnimation(
          !isFreeSpinsMode(setGameMode()) && !!isTurboSpin,
          isRespinMode(setGameMode()),
        );
      }

      //
      if (isRespinMode(setGameMode())) {
        eventManager.emit(EventTypes.SHOW_TINT, true, 0);
        eventManager.emit(EventTypes.SHOW_TINT, true, 1);
        eventManager.emit(EventTypes.SHOW_TINT, true, 3);
        eventManager.emit(EventTypes.SHOW_TINT, true, 4);

        AudioApi.play({ type: ISongs.XT004S_respin, stopPrev: true });
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipWinAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean, isRespin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      if (isRespin && i === 2) {
        continue;
      }
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id!);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getNonNullableValue(this.nextResult.bet.result.reelPositions),
              this.anticipationInfo,
              this.announceType,
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);

            const stopAnimDuration =
              PIXI.Loader.shared.resources['symbol_sc1']?.spineData?.findAnimation('stop').duration;
            const stopDelayAnimation = Tween.createDelayAnimation((stopAnimDuration ?? 0) * 1000);
            stopDelayAnimation.addOnComplete(() => {
              eventManager.emit(EventTypes.REELS_STOP_ANIMATION_COMPLETE, isTurboSpin);
            });
            stopDelayAnimation.start();
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getSynchroSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin, true);

      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet!.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              getNonNullableValue(this.nextResult.bet.result.reelPositions),
              this.anticipationInfo,
              'None',
              this.spinResult,
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);

            const stopAnimDuration =
              PIXI.Loader.shared.resources['symbol_sc1']?.spineData?.findAnimation('stop').duration;
            const stopDelayAnimation = Tween.createDelayAnimation((stopAnimDuration ?? 0) * 1000);
            stopDelayAnimation.addOnComplete(() => {
              eventManager.emit(EventTypes.REELS_STOP_ANIMATION_COMPLETE, isTurboSpin);
            });
            stopDelayAnimation.start();
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): ResultOf<typeof userBonusFragment> | undefined | null {
    return getBonusFromRewards(this.nextResult, 'FREE_SPIN');
  }

  private getSpecialRound(): ResultOf<typeof userBonusFragment> | undefined | null {
    return getBonusFromRewards(this.nextResult, 'SPECIAL_ROUND');
  }
  private updateRegularWin(winAmount: number) {
    setWinAmount(winAmount);
    setLastRegularWinAmount(winAmount);
  }
  private updateTotalWin(winAmount: number) {
    if (winAmount != 0) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    }
  }

  private checkBonusAndNextSpin() {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    const specialRoundBonus = this.getSpecialRound();
    const winAmount = this.nextResult?.bet.result.winCoinAmount!;
    const snowMeter = setNextResult()?.bet.data.features.gameRoundStore.snowMeter;
    const roundAddedTotal =
      snowMeter && snowMeter?.levelUp ? 6 + snowMeter?.totalRoundsCount! : snowMeter?.totalRoundsCount!;

    if (isBaseGameMode(mode) || mode === GameMode.REGULAR_RESPIN) {
      this.updateRegularWin(winAmount);
    } else if (isFreeSpinsBaseMode(mode) || mode === GameMode.FREE_SPINS_RESPIN) {
      this.updateTotalWin(this.nextResult!.bet.result.winCoinAmount);
    }

    if (freeSpinsBonus) {
      const isBonus = getBonusKind(freeSpinsBonus.bonus!.id);

      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
      });
      switch (isBonus) {
        case BonusKind.FREE_SPINS: {
          if (mode === GameMode.REGULAR) {
            setCurrentFreeSpinsTotalWin(winAmount);

            const startFreeSpinDelay = Tween.createDelayAnimation(500);
            startFreeSpinDelay.addOnComplete(() => {
              this.startFreeSpins();
            });
            startFreeSpinDelay.start();
          } else if (mode === GameMode.FREE_SPINS) {
            this.setState(SlotMachineState.IDLE);
          } else if (mode === GameMode.FREE_SPINS_RESPIN) {
            eventManager.emit(EventTypes.CHANGE_MODE, {
              mode: GameMode.FREE_SPINS,
              reelSetId: setCurrentBonus().bonus?.reelSetId!,
              reelPositions: getNonNullableValue(this.nextResult?.bet.result.reelPositions),
            });
          } else {
            //error
          }
          break;
        }
        case BonusKind.RESPIN_BASE: {
          //setCurrentFreeSpinsTotalWin(winAmount);
          if (checkNonBonusAutoSpinStop(this.nextResult)) {
            setIsAutoSpins(false);
          }

          const startReSpinDelay = Tween.createDelayAnimation(500);
          startReSpinDelay.addOnComplete(() => {
            this.startReSpins();
          });
          startReSpinDelay.start();

          break;
        }
        case BonusKind.RESPIN_FREE_SPIN: {
          const startReSpinDelay = Tween.createDelayAnimation(500);
          startReSpinDelay.addOnComplete(() => {
            this.startReSpins();
          });
          startReSpinDelay.start();
          break;
        }
      }
    } else if (specialRoundBonus) {
      // don't reach
    } else {
      switch (mode) {
        case GameMode.REGULAR: {
          this.setState(SlotMachineState.IDLE);
          break;
        }
        case GameMode.REGULAR_RESPIN: {
          eventManager.emit(EventTypes.CHANGE_MODE, {
            mode: GameMode.REGULAR,
            reelSetId: setDefaultReelSetId(),
            reelPositions: getNonNullableValue(this.nextResult?.bet.result.reelPositions),
            bgType: 'default',
          });
          //this.hideWinCount();
          setBrokenGame(false);
          this.isSlotBusyCallback();
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          break;
        }
        case GameMode.FREE_SPINS: {
          if (snowMeter && snowMeter?.currentRound >= roundAddedTotal) {
            this.waitEndFreeSpins();
          } else {
            this.setState(SlotMachineState.IDLE);
          }
          break;
        }
        case GameMode.FREE_SPINS_RESPIN: {
          this.waitEndFreeSpins();
          break;
        }
        case GameMode.BUY_FEATURE:
        default:
          //error
          break;
      }
    }

    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);

    //update round count
    if (mode === GameMode.FREE_SPINS || mode === GameMode.FREE_SPINS_RESPIN) {
      setCurrentBonus({
        ...setCurrentBonus(),
        rounds: roundAddedTotal,
        roundsPlayed: snowMeter?.currentRound!,
      });
    }
  }

  private waitEndFreeSpins() {
    const freespinsEndingDelay = Tween.createDelayAnimation(FREESPIN_ENDING_DELAY_DURATION);
    freespinsEndingDelay.addOnComplete(() => {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      eventManager.emit(EventTypes.END_FREESPINS);
    });
    freespinsEndingDelay.start();
  }

  private onCountUpEnd(): void {
    this.isSpinInProgressCallback();

    const animationChain = new AnimationChain();
    animationChain.appendAnimation(Tween.createDelayAnimation(100));

    //SnowCountAnimation
    if (isFreeSpinsGameMode(setGameMode())) {
      const snowMeter = setNextResult()!.bet.data.features.gameRoundStore.snowMeter;
      if (!snowMeter) {
        throw new Error('snowMeter is not available');
      }
      const isRoundAdd = snowMeter ? snowMeter.levelUp : false;
      const isLevelUp = isRoundAdd && snowMeter.level < SNOW_LEVEL_MAX ? true : false;

      if (this.anticipationInfo.totalSnowCount) {
        //skip?
        const snowCollectionDelay = hasWin(this.nextResult) ? 800 : 300;
        animationChain.appendAnimation(Tween.createDelayAnimation(snowCollectionDelay));
        const snowCollectAnimation = this.snowMeter.getSnowCollectAnimation(this.spinResult);
        snowCollectAnimation.addOnStart(() => {
          this.skipWinAnimations();
          eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
          eventManager.emit(EventTypes.START_SNOW_COLLECT_ANIMATION, this.anticipationInfo.totalSnowCount);
          setIsSnowCorrect(true);
        });
        animationChain.appendAnimation(snowCollectAnimation);
      }

      if (isLevelUp || isRoundAdd) {
        const snowLevel = snowMeter.level >= 1 ? snowMeter.level - 1 : 0;

        animationChain.appendAnimation(Tween.createDelayAnimation(500));

        const callback = () => {
          this.updateSnowMeter(0, snowLevel + 1, isLevelUp);
          this.checkBonusAndNextSpin();
        };
        const snowCount = setPreSnowCount() + snowMeter.countIncrease; // snow count is reset when level up
        const messageLevelUpProps: MessageLevelUpBannerProps = {
          callback: callback,
          preventDefaultDestroy: false,
          onInitCallback: () => {},
          snowLevel: snowLevel,
          isLevelUp: isLevelUp,
          snowCount: snowCount,
        };

        const levelUpDispDuration = Tween.createDelayAnimation(
          SNOW_LEVEL_TO_WAIT_DURATION_AUDIO[snowLevel + 1] ?? 3000,
        );
        levelUpDispDuration.addOnStart(() => {
          this.skipWinAnimations();
          bgmControl.fadeOutAll(100);
          eventManager.emit(EventTypes.CREATE_LEVELUP_BANNER, messageLevelUpProps);
          bannerWaitBgmPlay(
            SNOW_LEVEL_TO_LEVEL_UP_AUDIO[snowLevel + 1] ?? ISongs.XT004S_additional_spin_feature1,
            SNOW_LEVEL_TO_BG[snowLevel + 1] ?? 'freeSpin01',
            SNOW_LEVEL_TO_WAIT_DURATION_AUDIO[snowLevel + 1] ?? 3400,
          );
        });
        levelUpDispDuration.addOnComplete(() => {
          if (setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          }
        });
        animationChain.appendAnimation(levelUpDispDuration);
      }
      animationChain.addOnComplete(() => {
        setPreSnowCount(snowMeter.count);

        if (!isLevelUp && !isRoundAdd) {
          this.checkBonusAndNextSpin();
        }
      });
    } else {
      animationChain.addOnComplete(() => {
        this.checkBonusAndNextSpin();
      });
    }
    animationChain.start();
  }

  private dynamicReelSetChange(reelSetId: string): void {
    if (setReelSetId() !== reelSetId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSetId);
    }
  }

  private dynamicReelSetChangeRolling(reelSetId: string): void {
    if (setReelSetId() !== reelSetId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: this.slotConfig.reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0, 0, 0],
      });
    }
    if (setReelSetId() !== reelSetId) {
      setReelSetId(reelSetId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    setIsBuyFeatureSpin(false);
    setIsBuyFeaturePurchased(false);
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationInfo(_nextResult: ISettledBet): AnticipationInfo {
    let bonusAnticipation = false;
    let snowAnticipation = false;
    const isRespin = isRespinMode(setGameMode());
    let totalSnowCount = isRespin ? 3 : 0;
    let anticipationType: AnticipationType = 'None';
    const reelAnticipations: AnticipationReelType[] = [];
    const spinResult3x5 = transposeFlatIconToSlotIdArray(this.spinResult);

    if (!ANTICIPATION_ENABLE || setIsBuyFeatureSpin()) {
      return { type: 'None', reels: [], totalSnowCount };
    }

    if (spinResult3x5[1]?.includes(SlotId.SC2) && spinResult3x5[2]?.includes(SlotId.SC2)) {
      bonusAnticipation = true;
    }

    //set snow anticipation
    let snowStartReel = REELS_AMOUNT;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      let type: AnticipationType = 'None';
      let snowCount = 0;
      let soundType: ReelStopSoundType = 'normal';

      if (totalSnowCount >= 3 || isRespinMode(setGameMode())) {
        snowAnticipation = true;
        type = isRespinMode(setGameMode()) && i == 2 ? 'None' : 'Snow';
        if (snowStartReel === REELS_AMOUNT) {
          snowStartReel = i;
        }
      }
      for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
        if (!(isRespin && i === RESPIN_REEL_INDEX)) {
          if (SlotId.SC1 === spinResult3x5[i]![j]) {
            snowCount++;
            soundType = 'snow';
          }
          if (snowCount >= 3) {
            soundType = 'respin';
          }
        }
      }
      if (i === 1 && spinResult3x5[i]!.includes(SlotId.SC2) && !isRespinMode(setGameMode())) {
        soundType = 'bonus1';
      } else if (i === 2 && spinResult3x5[i]!.includes(SlotId.SC2) && spinResult3x5[i - 1]!.includes(SlotId.SC2)) {
        soundType = 'bonus2';
      } else if (
        i === 3 &&
        spinResult3x5[i]!.includes(SlotId.SC2) &&
        spinResult3x5[i - 1]!.includes(SlotId.SC2) &&
        spinResult3x5[i - 2]!.includes(SlotId.SC2)
      ) {
        soundType = 'bonus3';
      }
      totalSnowCount += snowCount;
      reelAnticipations.push({ type, snowCount, totalSnowCount, soundType });
    }
    //set anticipation type & bonus anticipation
    const SC2_FINAL_REEL = 3;
    if (bonusAnticipation && snowAnticipation && !isFreeSpinsMode(setGameMode())) {
      anticipationType = 'BonusSnow';
      if (reelAnticipations[SC2_FINAL_REEL]) {
        reelAnticipations[SC2_FINAL_REEL].type = 'Bonus';
      }
    } else if (bonusAnticipation && !isFreeSpinsMode(setGameMode())) {
      anticipationType = 'Bonus';
      if (reelAnticipations[SC2_FINAL_REEL]) {
        reelAnticipations[SC2_FINAL_REEL].type = 'Bonus';
      }
    } else if (snowAnticipation) {
      anticipationType = 'Snow';
    }

    return {
      type: anticipationType,
      reels: reelAnticipations,
      totalSnowCount,
      snowStart: snowStartReel !== REELS_AMOUNT ? snowStartReel : undefined,
    };
  }

  private getAnnounceType(_nextResult: ISettledBet): AnnounceType {
    if (isBuyFeatureMode() || isFreeSpinsMode(setGameMode()) || isRespinMode(setGameMode())) return 'None';

    const freeSpinsBonus = this.getFreeSpinBonus();
    const isBonus = freeSpinsBonus ? getBonusKind(freeSpinsBonus.bonus!.id) : undefined;
    const isFreeSpinBonus = isBonus === BonusKind.FREE_SPINS ? true : false;

    const snowCount = this.anticipationInfo.totalSnowCount as number;

    const info = AnnounceDataTbl.find((info) => info.bonus === isFreeSpinBonus && info.snowCount.includes(snowCount));

    return info && info.lotTbl.length > 0
      ? AnnounceConvTbl[getResultFromTbl(info.lotTbl, getRandomFromUUID(this.nextResult!.bet.id, 1000))]!
      : 'None';
  }

  private skipWinAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    let spinResult = [];
    let reelPositions = [];
    const reelSet: ReelSet = {
      id: result?.bet.reelSet?.id!,
      layout: this.slotConfig.reels.find((reelSet) => reelSet.id === result?.bet.reelSet?.id!)?.layout!,
    };
    if (isBuyFeatureMode()) {
      reelPositions = result!.bet.data.features.gameRoundStore.buyFeatureStore
        ? getNonNullableValue(result!.bet.data.features.gameRoundStore.buyFeatureStore.reelPositionsOverride).slice(
            0,
            REELS_AMOUNT,
          )
        : [];
      result?.bet?.result && (result.bet.result.reelPositions = reelPositions);
    } else if (isRespinMode(setGameMode())) {
      reelPositions = getNonNullableValue(
        result!.bet.data.features.gameRoundStore.freezeRespinData.respinReelPositions,
      ).slice(0, REELS_AMOUNT);
      result?.bet?.result && (result.bet.result.reelPositions = reelPositions);
    } else {
      reelPositions = getNonNullableValue(result!.bet.result.reelPositions).slice(0, REELS_AMOUNT);
    }

    spinResult = getSpinResult3x5({
      reelPositions,
      reelSet: reelSet,
      icons: this.slotConfig.icons,
    });

    this.spinResult = spinResult;
    setPrevReelsPosition(reelPositions);
    setPrevReelSet(reelSet);
    this.nextResult = result;
    setNextResult(result);
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult!.balance.placed);
    }
    this.anticipationInfo = this.getAnticipationInfo(this.nextResult);
    if (isBaseGameMode(setGameMode())) {
      this.announceType = this.getAnnounceType(this.nextResult); //TO DO
      ////const allow = this.announceType != 'None' || this.anticipationInfo.type !== 'None' ? false : true;
      //      const allow = this.announceType != 'None' ? false : true;
      if (this.announceType != 'None') {
        setIsAllowForceReelStop(false);
      }
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    setIsSpinShortCut(false);
    setForceReelStop(false);

    this.miniPayTableContainer.setSpinResult(this.spinResult);

    eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END);

    if (isRespinMode(setGameMode())) {
      const duration = getAnimationDurationFromSpine('re_ice', 'out');
      const delay = Tween.createDelayAnimation(duration);
      delay.addOnComplete(() => {
        this.setState(SlotMachineState.JINGLE);
      });
      delay.start();
      eventManager.emit(EventTypes.END_RESPIN_FREEZE_ANIMATION);
    } else {
      this.setState(SlotMachineState.JINGLE);
    }
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false, setIsAllowForceReelStop());
    this.setState(SlotMachineState.STOP);
  }

  public checkForceReelStop(allow: boolean): void {
    if (setIsSpinShortCut() && allow) {
      this.stopSpin();
    }
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(_width: number, _height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
        setIsFreeSpinsWin() ||
        setIsContinueAutoSpinsAfterFeature() ||
        setIsReSpinsWin(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode())) {
        this.skipWinAnimations();
        const freeSpinsRoundInterval = Tween.createDelayAnimation(
          setIsTurboSpin() ? FREE_SPINS_ROUND_INTERVAL_TURBO : FREE_SPINS_ROUND_INTERVAL,
        );
        freeSpinsRoundInterval.addOnComplete(() => {
          eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
        });
        freeSpinsRoundInterval.start();
      }
      setBrokenGame(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (
        getBonusKind(this.getFreeSpinBonus()?.bonus!.id!) === BonusKind.FREE_SPINS &&
        !isFreeSpinsMode(setGameMode())
      ) {
        const jingleDelay = Tween.createDelayAnimation(JINGLE_TO_WIN_DURATION);
        jingleDelay.addOnStart(() => {
          eventManager.emit(EventTypes.START_FS_WIN_ANIMATION);
          bgmControl.setRestrict(true);
          bgmControl.fadeOutAll(100);
          AudioApi.play({ type: ISongs.XT004S_feature_trigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        const stopDuration = PIXI.Loader.shared.resources['symbol_sc2']!.spineData?.findAnimation('stop').duration! * 1;
        const reelStopAnimationDelay = Tween.createDelayAnimation(stopDuration);
        reelStopAnimationDelay.addOnComplete(() => {
          jingleDelay.start();
        });
        reelStopAnimationDelay.start();
      } else if (hasWin(this.nextResult)) {
        const jingleDelay = Tween.createDelayAnimation(
          setIsTurboSpin() ? WIN_ANIM_START_DELAY / 2 : WIN_ANIM_START_DELAY,
        );
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (hasWin(this.nextResult)) {
        const winDelayDuration = isFreeSpinsMode(setGameMode()) ? 0 : 0;
        const winDelay = Tween.createDelayAnimation(winDelayDuration);
        winDelay.addOnComplete(() => {
          eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
        });
        winDelay.start();
      } else {
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
